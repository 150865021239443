$green-color: #2d491b;

label.control-label[for="comment"] {
  font-size: 0px;
}

label.control-label[for="comment"]:before {
  font-size: 14px;
  content: "Eventuella önskemål om leverans mm.";
}

.tc-masonry h3 {
  font-size: 15px
}

/*
Design - Solhem Inredning
April 2017
Design Ida Wikman
© Copyright 2019 Solhem Inredning AB
*/
/* -----Preview-bar----- */
.tws-theme-preview {
  position: fixed;
  z-index: 1000;
  width: 100%;
  bottom: 0px;
  left: 0;
  display: none;
}

.slot--cookies {
  position: fixed;
  z-index: 1000;
  width: 100%;
  bottom: 0px;
}

/* Typsnitt 
*:not(.fa) {
  font-family: 'TrebuchetMS';
}*/
.tws-theme-foundation-content--loading .fabrikk--header:after,
.tws-theme-foundation-content--finished .fabrikk--header:after {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  -o-animation: none;
  animation: none;
}

.fabrikk--header:after,
.fabrikk--header:before {
  display: none !important;
}

/*--Fullbredd--*/
body.startpage .fabrikk--content {
  padding: 0px;
}

.fabrikk--content-container {
  width: 100% !important;
  max-width: 1030px;
  margin: 0 auto;
  padding: 0px;
  background: #fff;
}

.container {
  width: 100%;
  max-width: 1170px;
}

/*--Breadcrumbs--*/
.slot--breadcrumbs {
  max-width: 1030px;
  margin: 0 auto;
  background: #fff;
}

.tws-breadcrumbs a {
  font-size: 11px;
}

.tws-breadcrumbs {
  padding-bottom: 15px;
  padding-top: 10px;
}

/*Tillbaka-*/
.fabrikk--back-to-top-link-text {
  font-size: 12px;
  color: #DFDFDF;
}

/*Pagnering*/
.btn-toolbar {
  display: none;
}

/*--Startsida--*/
.fabrikk--component-container>*:first-child {
  margin-top: 0px !important;
}

body .page-canvas,
body {
  background: url('https://shop.textalk.se/shop/2516/files/Design-element/solhembg.jpg');
}

body .page-canvas,
body {
  box-shadow: 0px 0px 6px 0px rgba(50, 50, 50, 0.20);
  max-width: 1030px;
  margin: 0 auto;
}

.fabrikk--component-container {
  background: #fff;
  padding-top: 0px;
}

.slot--component-container .tws-container--tws-custom-html,
.slot--component-container .tws-container--tws-article-display,
.slot--component-container .tws-container--tws-articles,
.slot--component-container .tws-container--tws-articles-two,
.slot--component-container .tws-container--tws-articles-connected,
.slot--component-container .tws-container--tws-newsletter-subscribe {
  padding: 0px;
}

.slot--component-container .tws-container--tws-content-box,
.slot--component-container .tws-container--tws-content-box-2x,
.slot--component-container .tws-container--tws-content-box-3x,
.slot--component-container .tws-container--tws-content-box-4x {
  padding: 0px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.tws-container--tws-image-carousel {
  padding-top: 0px;
  padding-bottom: 5px;
}

.fabrikk--component-container>* {
  margin-top: 5px;
  margin-bottom: 5px;
}

.instagram-block {
  padding: 0px !important;
  text-align: center;
  background: #D3C9C7 !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.intro-text-block h1 {
  color: #2C344B;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.intro-text-block h2 {
  color: #2C344B;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.intro-text-block p {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: #2C344B;
}

/*--Toprad--*/
.fabrikk--top-bar {
  max-width: 1030px;
  margin: 0 auto;
  background: #fff;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.slot--top-bar-html {
  font-size: 11px;
  text-decoration: none;
  color: #2C344B;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0px;
  width: 100%;
  text-align: center;
}

.slot--top-bar-html p {
  display: inline-block;
  position: relative;
}

.fabrikk--top-bar-right .btn {
  color: #000;
}

.fabrikk--top-bar-right {
  display: none;
}

.fabrikk--top-bar .topbar-menu {
  margin-right: 15px;
}

/*--Header--*/
.fabrikk--header {
  box-shadow: none;
  background: #fff;
  max-width: 1030px;
  margin: 0 auto;
  width: 100%;
  //   border-bottom: 1px solid #333;
}

.startpage .fabrikk--header {
  border-bottom: 0px;
}

.fabrikk--header-wrapper {
  background: transparent;
  padding-bottom: 0px;
  padding-top: 0px;
}

/*--Logo--*/
.slot--logo {
  max-width: 1030px;
  padding: 10px;
  padding-top: 0px;
}

.custom-logo {
  position: relative;
  display: inline-block;
  background: #000;
}

.header-logo {
  position: relative;
  display: inline-block;
}

.header-block {
  padding-top: 8px;
}


/*--Varukorg--*/
.fabrikk--header-right {
  display: none;
}

.slot--custom-cart {
  float: right;
  position: relative;
  top: 40px;
}

.tws-micro-cart--label {
  color: #fff;
  font-weight: 400;
}

.tws-theme-foundation-content--done .fabrikk--content {
  padding-top: 0px;
  padding: 15px;
}

.tws-micro-cart--counter {
  color: #fff;
  font-size: 16px;
  top: 14px;
  font-weight: 400;
}

.cart-block {
  width: calc(100% - 687px);
  height: 156px;
  vertical-align: top;
  background: #AEACAF;
  float: right;
}

.slot--shipping-notice {
  border-radius: 0px;
}

@media (min-width:768px) and (max-width:991px) {
  .slot--shipping-notice {
    top: 30px;
  }
}

@media (min-width:992px) {
  .slot--shipping-notice {
    top: 30px;
  }
}

/*Horisontell meny*/
.navbar-default .navbar-nav>li>a {
  font-family: 'Lato', sans-serif;
  color: #333 !important;
  font-size: 11px !important;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 0px;
  padding-right: 15px;
  background: transparent !important;
  letter-spacing: 2px;
}

.navbar-default .navbar-nav>li>a:hover {
  color: #D3C9C7 !important;
}

.tws-top-menu.navbar-default {
  border-top: 0px;
  margin-top: -10px;
}

@media (min-width:768px) {
  .tws-top-menu .navbar-nav .caret {
    display: none;
  }

  .tws-top-menu .dropdown>.dropdown-menu {
    display: none !important;
  }
}

.tws-top-menu>.container-fluid>.navbar-collapse {
  width: 100%;
}

.tws-top-menu .navbar-collapse .navbar-nav {
  max-width: none;
  width: 100%;
  text-align: center;
}

/*Ikoner*/
.tws-top-menu-item-0 a:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 12px;
  background: url(https://shop.textalk.se/shop/2516/files/Design-element/Ikoner/ikon-mobler.jpg);
  background-size: 17px 12px;
  margin-right: 10px;
  top: 2px;
  position: relative;
}

.tws-top-menu-item-1 a:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 15px;
  background: url('https://shop.textalk.se/shop/2516/files/Design-element/Ikoner/ikon-ute.jpg');
  background-size: 20px 15px;
  margin-right: 10px;
  top: 2px;
  position: relative;
}

.tws-top-menu-item-2 a:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 12px;
  background: url(https://shop.textalk.se/shop/2516/files/Design-element/Ikoner/ikon-barn.jpg);
  background-size: 17px 12px;
  margin-right: 10px;
  top: 2px;
  position: relative;
}

.tws-top-menu-item-3 a:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 12px;
  background: url(https://shop.textalk.se/shop/2516/files/Design-element/Ikoner/ikon-forbarn.jpg);
  background-size: 17px 12px;
  margin-right: 10px;
  top: 2px;
  position: relative;
}

.tws-top-menu-item-4 a:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 12px;
  background: url(https://shop.textalk.se/shop/2516/files/Design-element/Ikoner/ikon-inredning.jpg);
  background-size: 17px 12px;
  margin-right: 10px;
  top: 2px;
  position: relative;
}

/*
.tws-top-menu-item-5 a:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 12px;
  background: url(https://shop.textalk.se/shop/2516/files/Design-element/Ikoner/ikon-kok.jpg);
  background-size: 17px 12px;
  margin-right: 10px;
  top: 2px;
  position: relative;
}
*/
.tws-top-menu-item-5 a:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 12px;
  background: url(https://shop.textalk.se/shop/2516/files/Design-element/Ikoner/ikon-sovrum.jpg);
  background-size: 17px 12px;
  margin-right: 10px;
  top: 2px;
  position: relative;
}




@media (min-width:768px) {

  .tws-top-menu-item-8,
  .tws-top-menu--item-last {
    display: none !important;
  }
}

@media (max-width:839px) {
  .slot--custom-vertical-menu {
    display: none;
  }
}

@media (min-width:840px) {

  /*--Vertikal sidemenu--*/
  .slot--custom-vertical-menu {
    width: 192px;
    margin-left: 0px;
    margin-top: 0px;
    text-align: left;
    display: block;
    float: left;
    margin-bottom: 100px;
    background: #fafafa;
    padding: 15px;
    padding-left: 10px;
  }

  .articlegroup .fabrikk--content {
    display: inline-block;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
    width: 100%;
  }

  .twsArticlegroupSidemenu--heading {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #333;
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .twsArticlegroupSidemenu--heading span {
    visibility: hidden;
  }

  .twsArticlegroupSidemenu--article-count {
    display: none;
  }

  .twsArticlegroupSidemenu--sub-heading {
    padding: 3px;
    padding-left: 10px;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  .slot--article-group-custom-field {
    width: calc(100% - 192px);
    float: left;
    padding-left: 15px;
  }

  .slot--articles {
    width: calc(100% - 192px);
    float: left;
    padding-left: 15px;
  }

  .slot--article-groups {
    width: calc(100% - 192px);
    float: left;
    padding-left: 15px;
  }

  .twsArticlegroupSidemenu--sub-list {
    list-style-type: none;
    padding-left: 15px;
  }

  .twsArticlegroupSidemenu--sub-list a {
    text-transform: capitalize;
  }

  .slot--custom-vertical-menu a {
    color: #333;
  }

  .twsArticlegroupSidemenu--sub-sub {
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 5px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .twsArticlegroupSidemenu--sub-sub a:hover,
  .twsArticlegroupSidemenu--sub-sub a:focus,
  .twsArticlegroupSidemenu--sub-sub a:active {
    color: #D3C9C7 !important;
    text-decoration: none;
  }

  .twsArticlegroupSidemenu--sub-heading a:hover,
  .twsArticlegroupSidemenu--sub-heading a:focus,
  .twsArticlegroupSidemenu--sub-heading a:active {
    color: #D3C9C7 !important;
    text-decoration: none;
  }

  .twsArticlegroupSidemenu--selected a {
    color: #D3C9C7;
  }
}

/* Ny position SÖK knappen  */

@media (min-width:768px) {
  .slot--mega-menu {
    display: none !important;
  }

  body.fabrikk--horizontal-menu .tws-mega-menu--buttons .tws-mega-menu--search-button {
    display: block !important;
    background: transparent !important;
    color: #fff !important;
    letter-spacing: 0.5px;
    font-size: 14px !important;
    height: 30px;
  }
}

body.fabrikk--horizontal-menu .tws-mega-menu--buttons {
  right: 0;
  top: -65px;
  left: auto;
  height: 40px;
}

.custom-mega-menu-ny .tws-mega-menu--delimiter {
  display: none;
}

.mega-menu-search-ny .tws-mega-menu--search-bar .form-control {
  background: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.mega-menu-search-ny .tws-mega-menu--search-bar input.form-control {
  //   background: #f1f1f1f1;
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #F0EDE7;
}

@media (min-width:768px) {
  .tws-mega-menu--search-bar .tws-article-search {
    // border-bottom: 1px solid #2b2b2b !important;
    border: 0px;
  }
}

.mega-menu-search-ny .tws-mega-menu--search-bar .tws-article-search .dropdown-menu span {
  font-size: 13px;
}

@media (max-width:767px) {

  .custom-mega-menu-ny,
  .mega-menu-search-ny {
    display: none;
  }
}

.mega-menu-search-ny .tws-mega-menu--search-bar input.form-control:focus span {
  visibility: hidden;
}

.mega-menu-search-ny input[type=text].form-control {
  padding: 0px 40px 0px 55px;
}

/* END Ny position SÖK knappen  */



/*--Sokresultat-*/
.tws-article-search-page--heading {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

/*--Artiklar--*/
.slot--component-container .tws-container--tws-articles-two h2 {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.slot--component-container .tws-container--tws-articles-two .tws-util-heading.tws-articles-two--heading h2 {
  letter-spacing: 2px;
}

.tws-util-heading.tws-articles-two--heading {
  border-bottom: 1px solid;
}

.tws-article-list--article-name a {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  color: rgb(44, 52, 75);
}

.tws-article-list--article-name {
  text-align: center;
}

.tws-article-list--list .tws-list--type-grid .tws-article-list--product-image,
.tws-article-search-page--list .tws-list--type-grid .tws-article-list--product-image {
  border: 0px !important;
}

.using-mouse .tws-article-list--list .tws-list--list .thumbnail:hover,
.using-mouse .tws-article-search-page--list .tws-list--list .thumbnail:hover {
  border: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
}

.using-mouse .tws-article-list--list .tws-list--list .thumbnail:hover tws-img,
.using-mouse .tws-article-search-page--list .tws-list--list .thumbnail:hover tws-img {
  padding: 0px !important;
}

.tws-article-list--list .tws-list--type-grid .tws-article-list--price,
.tws-article-search-page--list .tws-list--type-grid .tws-article-list--price {
  width: 100%;
}

.tws-article-list--list .tws-list--type-grid .tws-api-price .tws-api--price-current,
.tws-article-search-page--list .tws-list--type-grid .tws-api-price .tws-api--price-current,
.tws-article-list--list .tws-list--type-grid .tws-api-price .tws-api--price-regular,
.tws-article-search-page--list .tws-list--type-grid .tws-api-price .tws-api--price-regular {
  text-align: center;
  display: block;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  font-size: 12px;
}

.tws-list--grid-item {
  background: url('/shop/2516/files/skugga.png') no-repeat bottom center;
  padding-bottom: 15px;
}

.tws-article-list--list .tws-list--type-grid .tws-api-price .tws-api--price-regular+.tws-api--price-current,
.tws-article-search-page--list .tws-list--type-grid .tws-api-price .tws-api--price-regular+.tws-api--price-current {
  color: #935c77;
}

/*--Labels--*/
.tws-article-labels .tws-article-labels--label .tws-article-labels--label-text {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 13px 9px !important;
  border-radius: 0px;
  font-size: 12px;
  font-weight: 400;
  display: block;
  text-align: center;
}

.tws-article-labels--label.tws-article-labels--special-offer .tws-article-labels--label-text {
  padding: 5px 6px !important;
}

.tws-article-labels--special-offer .tws-article-labels--label-text {
  background: #D3C9C7;
  border-color: #D3C9C7;
  color: #000;
  letter-spacing: 2px;
}

.tws-article-labels--label.tws-article-labels--new .tws-article-labels--label-text {
  padding: 5px 6px !important;
  background: #000000;
  border-color: #000;
  color: #ffffff;
  font-size: 10px;
  letter-spacing: 2px;
}

.tws-articles-two--labels {
  left: 0px;
}

.using-mouse .tws-article-list--list .tws-list--list .thumbnail:hover .tws-article-labels,
.using-mouse .tws-article-search-page--list .tws-list--list .thumbnail:hover .tws-article-labels {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

/*--Knappar--*/
.slot--article-buy-button .btn-primary {
  border: 1px solid #2d491b !important;
  background: #2d491b !important;
}

.tws-article-list--list .tws-list--type-grid .tws-article-list--choices-button,
.tws-article-list--list .tws-list--type-grid .tws-article-list--buy-button .tws-article-buy-button-with-modal--buy-button {
  height: 30px;
  line-height: 15px;
  border-radius: 0px;
  border: 1px solid #2d491b !important;
  background: #2d491b !important;
}

.tws-article-buy-button-with-modal--buy-button {
  border-radius: 0px;
  border: 1px solid #2d491b !important;
  background: #2d491b !important;
}

.btn-primary .ladda-label,
.tws-article-list--choices-button-text {
  font-weight: 300 !important;
  font-size: 12px !important;
  color: #fff !important;
}

.article .btn-primary .ladda-label {
  color: #fff !important;
}

.tws-article-list--buy-button .btn {
  font-weight: 300;
}

.tws-article-list--list .tws-list--type-grid .tws-article-list--buy-button,
.tws-article-search-page--list .tws-list--type-grid .tws-article-list--buy-button {
  width: 100%;
  text-align: center;
}

.btn-primary {
  border-radius: 0px !important;
  border: 1px solid #c3c3c3 !important;
  color: #000 !important;
  background: #001802 !important;
}

.btn {
  font-size: 13px !important;
  font-weight: 400 !important;
  border-radius: 0px;
  border: 0px !important;
}

.checkout .btn-primary,
.cart .btn-primary {
  border-radius: 0px;
  border: 1px solid #EAEAEA !important;
  background: #EAEAEA !important;
}

.btn.tws-checkout--pay-button {
  border-radius: 0px;
  border: 1px solid $green-color !important;
  background: $green-color !important;
  font-weight: 100 !important;
  color: #fff !important;
}

.btn.tws-checkout--pay-button .ladda-label {
  font-weight: 100 !important;
  color: #fff !important;
}

.tws-contact-form--submit-wrapper .btn-primary {
  border-radius: 0px;
  border: 1px solid #EAEAEA !important;
  background: #EAEAEA !important;
  font-weight: 100 !important;
}

.fabrikk--article .tws-article-buy-button-with-modal--out-of-stock-message .btn-primary {
  background: #001802 !important;
  color: #fff !important;
  font-size: 11px !important;
  height: 40px !important;
  line-height: 10px;
}

.modal .btn-primary.tws-article-buy-button-with-modal--to-checkout {
  border: 1px solid #2d491b !important;
  background: #2d491b !important;
  color: #fff !important;
}


/*--Eget innehåll--*/
.customPageItem.customPageHtml h1 {
  color: #2C344B;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 10px;
  margin-top: 0;
  letter-spacing: 2px;
  line-height: 18px;
}

.customPageItem.customPageHtml h2 {
  color: #2C344B;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 10px;
  margin-top: 0;
  letter-spacing: 2px;
}

.customPageItem.customPageHtml p,
.customPageItem.customPageHtml {
  color: #2C344B;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 10px;
  margin-top: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.customPageItem.customPageArticleSelection h1 {
  margin: 20px 0 10px 0;
  color: #2c344b;
  border-bottom: 1px solid #2c344b;
  line-height: 25px;
  letter-spacing: 2px;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
}

.customPageItem.customPageArticlegroupSelection h1 {
  margin: 20px 0 10px 0;
  color: #2c344b;
  border-bottom: 1px solid #2c344b;
  line-height: 25px;
  letter-spacing: 2px;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
}

/*--Artikelgrupper text--*/
.customPageItem.customPageHtml .articlegroup-text {
  max-width: 600px;
  padding: 35px 35px 50px 35px;
  background: rgba(250, 250, 250, 0.8);
  position: relative;
  top: 55px;
  margin: 0 auto;
  text-align: center;
}

.customPageItem.customPageHtml .articlegroup-text p {
  font-size: 14px;
  letter-spacing: 0px;
}

/*--Artikelgrupper bilder--*/
.customPageItem.customPageHtml .articlegroup-header {
  min-height: 380px;
  background-repeat: no-repeat;
  background-size: cover;
}

/*Kontaktforumlar*/
.fabrikk--contact-heading {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

/*--Artikeltabell--*/
.articlegroup .fabrikk--content-wrapper {
  padding-left: 10px;
}

.customPageItem.customPageArticleSelection .tws-util-heading h2 {
  text-align: center;
  font-size: 13px;
  border-bottom: 1px solid #ebebeb;
}

.tws-article-list--article-name .tws-util-heading--heading {
  border-bottom: 0px !important;
}

.tws-articlegroup-list .caption a {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
}

.tws-articlegroup-list .tws-list--grid-item .thumbnail {
  border: 0px;
}

.tws-articlegroup-list .tws-list--grid-item {
  padding-bottom: 0px;
}

.tws-articlegroup-list .tws-list--grid-item .caption {
  padding-bottom: 0px;
}

@media (min-width:992px) {
  .tws-articlegroup-list .tws-list--grid-item .caption {
    position: relative;
    width: 100%;
    overflow: hidden;
    bottom: 110px;
    background: rgba(250, 250, 250, 0.8);
    padding-bottom: 40px;
  }

  .tws-articlegroup-list .caption a {
    font-size: 13px !important;
  }

  .tws-articlegroup-list .tws-list--grid-item,
  .tws-articlegroup-list .tws-list--grid-item .thumbnail {
    margin-bottom: 0px;
    max-height: 185px;
  }
}

.tws-articlegroup-list .thumbnail {
  height: auto;
}

/*--Artikelsida--*/
.slot--article-price {
  margin-bottom: 20px;
}

.tws-article-introduction--read-more {
  display: none;
}

.article .tws-unique-selling-points .fa {
  color: #2C491A;
}

.tws-article-name h1 {
  color: #2C344B;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 2px;
  margin-top: 0;
  letter-spacing: 2px;
}

.tws-article-images--slick-big .slick-slide .tws-img--container {
  border: 0px;
}

.tws-article-quantity .input-group input {
  height: 41px;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.fabrikk--article .slot--article-stock-shipping {
  min-height: 0px;
}

.slot--article-description .tws-article-description--text h2,
.tws-article-introduction--text h2 {
  line-height: 1.6875;
  font-size: 13px;
  margin-top: 0px;
}

.slot--article-description .tws-article-description--heading {
  display: none;
}

.slot--article-description .tws-article-description--text,
.tws-article-introduction--text {
  font-size: 12px;
  line-height: 18px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.tws-article-description--article-number {
  display: none;
}

.tws-article-price .tws-api-price .tws-api--price-current,
.tws-horizontal-flip-list--price .tws-api-price .tws-api--price-current {
  font-size: 16px;
}

.tws-article-description--show-more .btn {
  font-size: 11px !important;
}

.tws-article-introduction--text:empty,
.fabrikk--article .slot--article-stock-shipping:empty {
  display: none;
}

.tws-article-price .tws-api-price .tws-api--price-current {
  display: block;
}

.fabrikk--article .fabrikk--buy-button-group {
  padding-top: 0px;
  padding-bottom: 20px;
}

.tws-article-price .tws-api-price .tws-api--price-regular+.tws-api--price-current,
.tws-horizontal-flip-list--price .tws-api-price .tws-api--price-regular+.tws-api--price-current {
  color: #000;
}

.slot--unique-selling-points .tws-unique-selling-points .tws-unique-selling-points--text {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.tws-container--tws-article-visited h3,
.tws-container--tws-article-related h3,
.tws-container--tws-article-manually-related h3,
.tws-article-attachments--title {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
  border-bottom: 1px solid;
  text-align: center;
  padding-bottom: 10px;
}

.tws-horizontal-flip-list--name {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 11px !important;
  color: rgb(44, 52, 75);
  text-align: center;
}

.tws-horizontal-flip-list--price .tws-api-price .tws-api--price-current {
  font-size: 13px;
  font-weight: 400;
  font-size: 12px;
}

.tws-article-price .tws-api-price .tws-api--price-regular,
.tws-horizontal-flip-list--price .tws-api-price .tws-api--price-regular {
  font-size: 15px;
}

/*--Modal--*/
.tws-article-buy-button-with-modal--dialog .media-body {
  text-transform: uppercase;
  font-size: 15px;
}

.modal .h4 {
  text-transform: uppercase;
  font-size: 15px;
}

.modal .h3,
.modal h3 {
  text-align: center;
  font-size: 13px;
}

.modal .btn-primary {
  border: 1px solid #EAEAEA !important;
  background: #EAEAEA !important;
}

.tws-article-images--dialog-close {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/*--Kassa--*/
.fabrikk--checkout-return-page .btn-primary {
  color: #333 !important;
  background: #ebebeb !important;
}

.tws-checkout-shortcuts {
  padding-top: 5px;
  text-align: center;
}

.tws-checkout-shortcuts--ship-to {
  font-size: 11px;
  text-transform: uppercase;
}

.checkout .slot--custom-search {
  display: none !important;
}

.checkout .slot--custom-vertical-menu {
  display: none !important;
}

body.cart .fabrikk--header-left,
body.checkout .fabrikk--header-left {
  float: none;
}

.tws-checkout-back-button button {
  position: absolute;
  background: transparent !important;
  color: #fff !important;
}

.fa-arrow-left:before {
  font-size: 10px;
}

.fabrikk--cart,
.fabrikk--summary,
.fabrikk--checkout-method {
  padding: 15px;
}

.tws-checkout-back-button--text {
  font-size: 11px;
}

.tws-cart--th-quantity,
.tws-cart--th-article-no,
.tws-cart--th-article,
.text-right.tws-cart--th-price,
.text-right.tws-cart--th-vat-excl,
.text-right.tws-cart--th-vat-incl {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 11px;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.tws-checkout-cart.table-striped>tbody>tr:nth-child(odd)>td {
  background: #fff;
}

.tws-cart--article.tws-cart--td-article {
  font-size: 11px;
  text-transform: uppercase;
}

.tws-cart--td-article-no {
  font-size: 12px;
}

.checkout .table>thead>tr>th {
  border-bottom: 0px;
}

.fabrikk--cart {
  margin-bottom: 0px;
}

.fabrikk--checkout-method {
  padding-top: 0px;
}

.tws-cart .tws-checkout-cart .tws-cart--icon i::before {
  color: #D3C9C7;
}

.fabrikk--cart h2:first-child,
.fabrikk--summary h2,
.fabrikk--checkout-method h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #000;
  letter-spacing: 2px;
}

.tws-checkout--radio {
  border-bottom: 1px solid #ebebeb !important;
}

.tws-checkout--radio .btn-default .tws-checkout--delivery-title {
  font-size: 13px;
  text-transform: uppercase;
}

.tws-checkout .tws-checkout--payment-methods-list a.tws-checkout--payment-method.active {
  border-bottom: 1px solid #ebebeb !important;
  border: 0px;
  border-radius: 0px !important;
}

.tws-checkout--v2 .tws-checkout--payments-expand.tws-checkout--visibility-hidden {
  visibility: visible;
  transform: none !important;
  height: auto !important;
}

.tws-checkout .tws-checkout--payment-methods-list a.tws-checkout--payment-method.active:hover,
.tws-checkout .tws-checkout--payment-methods-list a.tws-checkout--payment-method.active:hover {
  background: #fff;
}

.tws-checkout .tws-checkout--payment-methods-list a.tws-checkout--payment-method.active:focus,
.tws-checkout .tws-checkout--payment-methods-list a.tws-checkout--payment-method.active:focus {
  background: #fff;
}

.tws-checkout .tws-checkout--payment-methods-list a.tws-checkout--payment-active.active,
.tws-checkout .tws-checkout--payment-methods-list a {
  background: #fff !important;
  box-shadow: none !important;
}

.tws-checkout--payment-methods-list a.tws-checkout--payment-method.active:focus {
  transform: none !important;
}

.tws-checkout--v2 .tws-checkout--payments-expand {
  transition: none !important;
}

.tws-checkout--payments-expand {
  border: 0px;
}

.tws-checkout--v2 .tws-checkout--payment-active span.fa {
  display: none;
}

.tws-checkout--box.tws-checkout--select-delivery h4,
.tws-checkout--box h4 {
  text-transform: uppercase;
  font-size: 13px;
  background: #D3C9C7;
  padding: 13px;
}

.tws-checkout--payment-title {
  font-size: 13px;
  text-transform: uppercase;
}

.tws-checkout .tws-checkout--payment-methods-list a.tws-checkout--payment-method::before {
  content: '';
  color: #333;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(51, 51, 51, 0.3);
}

.tws-cart-summary .tws-cart-summary--items-count {
  font-weight: 400;
  font-size: 14px;
}

.tws-cart-summary .tws-cart-summary--sum-row td {
  font-size: 14px;
}

.checkout .tws-unique-selling-points--text {
  text-transform: uppercase;
  font-size: 11px;
}

.fabbrikk--cart-help {
  font-size: 12px;
}

.tws-checkout--radio .btn-default:not(.active) {
  border-bottom: 1px solid #ebebeb;
}

/*----Skyltfönster----*/
.tws-container--tws-content-box-3x .tws-content-box--cell,
.tws-container--tws-content-box-4x .tws-content-box--cell,
.tws-container--tws-content-box-2x .tws-content-box--cell {
  padding: 0 0px;
}

@media (min-width:992px) {
  .slot--component-container .tws-container--tws-content-box-3x .tws-content-box--cell {
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .slot--component-container .tws-container--tws-content-box-3x .tws-content-box:hover .tws-content-box--cell {
    opacity: 1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .slot--component-container .tws-container--tws-content-box-2x .tws-content-box--cell {
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .slot--component-container .tws-container--tws-content-box-2x .tws-content-box:hover .tws-content-box--cell {
    opacity: 1;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
  }
}




/*1 fönster*/
.image-right .tws-content-box--cell {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 80px;
  top: 80px;
  padding: 50px 30px 50px 30px;
  right: 40px;
  width: 35%;
}

.image-left .tws-content-box--cell {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 80px;
  top: 80px;
  padding: 50px 30px 50px 30px;
  left: 40px;
  width: 35%;
}

.slot--component-container .tws-container--tws-content-box .tws-content-box--cell .tws-content-box--third-line {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.slot--component-container .tws-container--tws-content-box .tws-content-box--cell:hover .tws-content-box--third-line {
  background: #fff;
  color: #000 !important;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.slot--component-container .tws-container--tws-content-box .tws-content-box--table:hover {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.slot--component-container .tws-container--tws-content-box .tws-content-box--table {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.slot--component-container .tws-container--tws-content-box .tws-content-box--first-line {
  font-size: 15px;
  padding: 15px;
  font-weight: 400;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 10px;
  line-height: 27px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.slot--component-container .tws-container--tws-content-box .tws-content-box--second-line {
  font-size: 12px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-weight: 300;
  text-transform: uppercase;
}

.tws-container--tws-content-box .tws-content-box--third-line {
  font-size: 11px;
  margin-left: 15px;
  margin-right: 15px;
  display: inline-block;
  line-height: 15px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 100;
  text-rendering: auto;
  border: 1px solid #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/*2 fönster*/
.tws-container--tws-content-box-2x .tws-content-box--cell {
  padding: 20px;
}

.slot--component-container .tws-container--tws-content-box-2x .tws-content-box--first-line {
  font-size: 13px !important;
  font-weight: 600;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 23px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.slot--component-container .tws-container--tws-content-box-2x .tws-content-box--second-line {
  font-size: 15px !important;
  font-weight: 600;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 20px;
  text-transform: uppercase;
  margin-top: 0px;
}

.tws-container--tws-content-box-2x .tws-content-box--third-line {
  font-size: 11px !important;
  font-weight: 400;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 20px;
  text-transform: uppercase;
  margin-top: 0px;
}

/*Bild text block*/
.bildtextblock .tws-content-box-2x--box-2 .tws-content-box--cell {
  background-color: transparent !important;
  opacity: 1 !important;
  padding: 40px !important;
}

.bildtextblock .tws-content-box-2x--box-2 .tws-content-box--cell .tws-content-box--first-line {
  font-size: 17px !important;
  font-weight: 600;
  padding-bottom: 0px;
  margin-bottom: 5px;
  line-height: 23px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.bildtextblock .tws-content-box-2x--box-2 .tws-content-box--cell .tws-content-box--second-line {
  font-size: 13px !important;
  font-weight: 300;
  padding-bottom: 0px;
  margin-bottom: 15px;
  line-height: 23px;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.bildtextblock .tws-content-box-2x--box-2 .tws-content-box--cell .tws-content-box--third-line {
  font-size: 11px !important;
  font-weight: 300;
  padding-bottom: 0px;
  margin-bottom: 10px;
  line-height: 19px;
  letter-spacing: 2px !important;
  text-transform: inherit !important;
}

/*3 fönster*/
.tws-container--tws-content-box-3x .tws-content-box--cell {
  padding: 30px 20px 30px 20px;
}

.slot--component-container .tws-container--tws-content-box-3x .tws-content-box--first-line {
  font-size: 13px !important;
  font-weight: 600;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 23px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.slot--component-container .tws-container--tws-content-box-3x .tws-content-box--second-line {
  font-size: 15px !important;
  font-weight: 600;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 20px;
  text-transform: uppercase;
  margin-top: 0px;
}

.tws-container--tws-content-box-3x .tws-content-box--third-line {
  font-size: 11px !important;
  font-weight: 400;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 20px;
  text-transform: uppercase;
  margin-top: 0px;
}

/*4 fönster*/
.tws-container--tws-content-box-4x .tws-content-box--cell {
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 20px;
  padding: 10px;
}

.slot--component-container .tws-container--tws-content-box-4x .tws-content-box--first-line {
  font-size: 12px !important;
  font-weight: 600;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.slot--component-container .tws-container--tws-content-box-4x .tws-content-box--second-line {
  font-size: 11px !important;
  font-weight: 400;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 19px;
  text-transform: uppercase;
  margin-top: 0px;
}

.tws-container--tws-content-box-4x .tws-content-box--third-line {
  font-size: 11px !important;
  font-weight: 400;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 19px;
  text-transform: uppercase;
  margin-top: 0px;
}

/*--Footer--*/
.fabrikk--footer {
  background: url('https://shop.textalk.se/shop/2516/files/Design-element/solhembg.jpg');
  padding: 0px;
  color: #000;
  font-size: 12px;
  line-height: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.fabrikk--footer a {
  color: #000;
}

.fabrikk--footer .container {
  max-width: 1030px;
  background: #F0EDE7;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 90px;
}

.fabrikk--footer-right {
  text-align: left;
}

.slot--newsletter-footer {
  margin-bottom: 0px;
}

.fabrikk--footer .footer-heading {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 2px;
  display: block;
  font-weight: 500;
}

/*--Mobil--*/
/*Logos*/
@media (min-width:860px) and (max-width:991px) {
  .custom-logo img {
    max-height: 145px;
    width: auto;
  }

  .header-logo img {
    max-height: 145px;
    width: auto;
  }

  .cart-block {
    width: calc(100% - 638px);
    height: 145px;
  }
}

@media (min-width:678px) and (max-width:859px) {
  .custom-logo img {
    max-height: 135px;
    width: auto;
  }

  .header-logo img {
    max-height: 135px;
    width: auto;
  }

  .cart-block {
    width: calc(100% - 595px);
    height: 135px;
  }

  .tws-micro-cart--label,
  body.fabrikk--horizontal-menu .tws-mega-menu--buttons .tws-mega-menu--search-button {
    font-size: 12px !important;
  }

  .tws-micro-cart--counter {
    font-size: 14px;
    top: 16px;
  }

  .slot--custom-cart {
    top: 35px;
  }
}

@media (max-width:1024px) {
  .header-block {
    text-align: left;
  }
}

@media (min-width:840px) and (max-width:991px) {
  .fabrikk--body {
    padding-left: 10px;
    padding-right: 10px;
  }

  body .page-canvas,
  body {
    background: #fff !important;
  }
}

@media (max-width:991px) {
  body.fabrikk--horizontal-menu .tws-mega-menu--buttons {
    top: -50px;
  }

  body.startpage .fabrikk--content {
    padding: 15px;
    padding-top: 0px;
  }

  .image-right .tws-content-box--cell {
    background-color: rgba(0, 0, 0, 0.5);
    position: static;
    bottom: 80px;
    top: 80px;
    padding: 20px !important;
    right: 40px;
    width: 40%;
  }

  .image-left .tws-content-box--cell {
    background-color: rgba(0, 0, 0, 0.5);
    position: static;
    bottom: 80px;
    top: 80px;
    padding: 20px !important;
    left: 40px;
    width: 40%;
  }

  .slot--component-container .tws-container--tws-content-box .tws-content-box--second-line {
    font-size: 11px !important;
  }

  .slot--component-container .tws-container--tws-content-box .tws-content-box--first-line {
    font-size: 15px !important;
  }

  .tws-container--tws-content-box .tws-content-box--third-line {
    padding: 7px !important;
  }

  .navbar-default .navbar-nav>li>a {
    font-size: 10px !important;
    padding-right: 10px;
  }
}

@media (min-width:768px) and (max-width:819px) {
  .navbar-default .navbar-nav>li>a {
    padding-right: 8px;
  }

  .tws-top-menu>.container-fluid>.navbar-collapse {
    padding-left: 5px;
    padding-right: 5px;
  }

  .tws-top-menu-item-0 a:before,
  .tws-top-menu-item-1 a:before,
  .tws-top-menu-item-2 a:before,
  .tws-top-menu-item-3 a:before,
  .tws-top-menu-item-4 a:before,
  .tws-top-menu-item-5 a:before,
  .tws-top-menu-item-6 a:before {
    margin-right: 6px;
  }
}

@media (max-width:767px) {
  .slot--component-container .tws-container--tws-content-box .tws-content-box--second-line {
    font-size: 10px !important;
  }

  .slot--component-container .tws-container--tws-content-box .tws-content-box--first-line {
    font-size: 13px !important;
    margin-bottom: 0px;
  }

  .tws-container--tws-content-box .tws-content-box--third-line {
    padding: 5px !important;
    margin-top: 7px;
    font-size: 10px;
  }

  .slot--component-container .tws-container--tws-content-box {
    padding-bottom: 0px !important;
  }

  .slot--component-container .tws-container--tws-content-box-3x .tws-content-box--second-line {
    font-size: 13px !important;
  }

  .bildtextblock .tws-content-box-2x--box-2 .tws-content-box--cell .tws-content-box--first-line {
    font-size: 13px !important;
  }

  .bildtextblock .tws-content-box-2x--box-2 .tws-content-box--cell .tws-content-box--second-line {
    font-size: 11px !important;
  }

  body.startpage .fabrikk--content {
    padding: 0px;
    padding-top: 0px;
  }

  .slot--component-container .tws-container--tws-custom-html,
  .slot--component-container .tws-container--tws-article-display,
  .slot--component-container .tws-container--tws-articles,
  .slot--component-container .tws-container--tws-articles-two,
  .slot--component-container .tws-container--tws-articles-connected,
  .slot--component-container .tws-container--tws-newsletter-subscribe {
    padding: 15px;
  }

  .slot--component-container .tws-container--tws-content-box,
  .slot--component-container .tws-container--tws-content-box-2x,
  .slot--component-container .tws-container--tws-content-box-3x,
  .slot--component-container .tws-container--tws-content-box-4x {
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobil-bildspel {
    display: block !important;
    margin-top: 0px !important;
  }

  .tws-container--tws-image-carousel {
    display: none;
  }

  .header-logo {
    display: none;
  }

  .cart-block {
    display: none;
  }

  .slot--custom-cart {
    display: none;
  }

  .slot--custom-search {
    display: none;
  }

  .slot--custom-vertical-menu {
    display: none;
  }

  .slot--logo {
    background: #121329;
    padding: 0px;
    text-align: center;
  }

  .custom-logo {
    margin: 0 auto;
    top: 0px;
    background: #121329;
  }

  .custom-logo img {
    max-width: 115px;
    height: auto;
  }

  .slot--mega-menu {
    display: block;
    padding-top: 10px;
  }

  .tws-mega-menu--buttons,
  .tws-mega-menu--open-button {
    height: auto;
  }

  .tws-mega-menu .btn {
    display: none;
  }

  .fabrikk--content-container {
    margin-top: 0px;
  }

  .slot--top-bar-html {
    font-size: 9px;
    letter-spacing: 1px;
    text-align: center;
  }

  .fabrikk--component-container {
    padding-top: 0px;
  }

  .fabrikk--footer-right {
    text-align: center;
  }

  /*Sök*/
  .tws-mega-menu--search-bar input.form-control {
    background: #fff;
    border: 1px solid #2b2b2b !important;
    box-shadow: none !important;
    transition: none !important;
  }

  .tws-mega-menu--search-bar .tws-article-search span::before {
    color: #000;
  }

  .tws-mega-menu--search-bar .form-control {
    font-size: 11px !important;
  }

  .tws-mega-menu--search-bar .tws-article-search span {
    top: 4px;
  }

  .tws-mega-menu--search-bar .tws-article-search {
    border: 0px;
    margin: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .tws-mega-menu--search-bar input.form-control {
    height: 40px;
  }

  .tws-mega-menu--search-bar,
  .tws-mega-menu--search-bar.open {
    height: 45px;
  }

  /*Vänster meny*/
  .fabrikk--body .tws-vertical-menu .tws-vertical-menu--open-btn {
    top: 58px;
    left: 20px;
  }

  .tws-vertical-menu--open-btn.btn-default {
    color: #fff !important;
  }

  .tws-vertical-menu .tws-vertical-menu--close {
    display: block;
    color: #D3C9C7;
  }

  .tws-vertical-menu--level a {
    font-weight: 400 !important;
  }

  .tws-vertical-menu .tws-vertical-menu--search {
    display: block;
  }

  .tws-vertical-menu--search .fa {
    font-size: 16px;
    margin-right: 5px;
    margin-bottom: 10px;
    color: #D3C9C7;
  }

  .tws-vertical-menu--search p {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }

  .tws-vertical-menu--search a {
    color: #000;
  }

  .tws-vertical-menu--search {
    padding-top: 20px;
  }

  .fabrikk--footer {
    text-align: center !important;
    text-transform: uppercase;
    line-height: 20px;
  }

  /*Fixering*/
  .fabrikk--header {
    position: fixed;
    padding-top: 26px;
    border-bottom: 0px;
  }

  .fabrikk--top-bar {
    position: fixed;
  }

  .fabrikk--body {
    padding-top: 190px;
  }

  .fabrikk--body .tws-vertical-menu .tws-vertical-menu--open-btn {
    position: fixed;
  }

  .fabrikk--top-bar .topbar-menu {
    margin-right: 10px;
  }

  .slot--top-bar-html {
    font-size: 8px;
    text-align: center;
  }

  /*Varukorg*/
  .fabrikk--header-right {
    display: block;
    bottom: 6px;
  }

  .tws-micro-cart--counter {
    font-size: 11px;
  }

  .tws-micro-cart--counter::before {
    left: -1px;
  }
}

@media (min-width:768px) {
  .mobil-bildspel {
    display: none;
  }
}



/*Meny mobil*/
@media (max-width:767px) {
  .slot--horizontal-menu {
    margin-left: -15px;
    margin-right: -15px;
  }

  .slot--horizontal-menu .tws-top-menu--controls {
    display: block !important;
  }

  .slot--horizontal-menu .tws-top-menu .navbar-nav li {
    border-bottom: 0;
    padding: 7px 15px;
  }

  .slot--horizontal-menu .tws-top-menu .container-fluid>.navbar-collapse {
    border-top: 0;
  }

  .slot--horizontal-menu {
    background: #151515;
  }

  .slot--horizontal-menu .tws-top-menu li a {
    color: #333 !important;
    padding: 4px 20px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .tws-top-menu--sub-item .tws-top-menu--xs-link {
    padding-left: 0px !important;
    text-transform: uppercase;
    font-size: 11px !important;
  }

  .tws-top-menu--sub-item .tws-top-menu--xs-link:before {
    display: none;
  }

  .slot--horizontal-menu .tws-top-menu li a:hover {
    color: #D3C9C7 !important;
  }

  .slot--horizontal-menu .tws-top-menu .navbar-nav {
    padding: 0px 0;
  }

  .slot--horizontal-menu .tws-top-menu--xs.open .tws-top-menu--sub-menu {
    padding: 10px 0 0 15px;
    position: relative;
    float: none;
    background: transparent;
  }

  .slot--horizontal-menu .tws-top-menu>.container-fluid>.navbar-collapse {
    min-height: calc(100vh - 60px);
    overflow-y: auto !important;
    height: 100% !important;
    position: absolute;
    background: #fff;
    top: 0px;
    padding: 0;
    box-shadow: none;
    z-index: 2;
    max-width: 300px;
    border-right: 1px solid #333;
  }

  .slot--horizontal-menu .tws-top-menu--sub-menu>li:last-child {
    border-bottom: 0 !important;
  }

  .slot--horizontal-menu .tws-top-menu .tws-top-menu--controls div {
    height: auto;
    display: none;
  }

  .slot--horizontal-menu .tws-top-menu li.hidden-xs {
    display: none !important;
  }

  .slot--horizontal-menu .tws-top-menu li.visible-xs {
    display: block !important;
    border-bottom: 1px solid #ebebeb;
  }

  .slot--horizontal-menu .navbar-collapse {
    display: block !important;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition: all 0.25s ease-out;
  }

  .slot--horizontal-menu .navbar-collapse.collapse {
    display: block !important;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .tws-top-menu .navbar-header .navbar-toggle::after {
    color: #fff;
    font-size: 17px;
  }

  .tws-micro-cart--counter {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }

  .tws-top-menu .navbar-header {
    left: 20px;
  }

  .body.overlay:before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
  }
}

@media (min-width:768px) {
  .fabrikk--top-bar .topbar-menu-phone {
    display: none !important;
  }
}


/* Design egna sidor */
.custom-box-1 {
  height: 350px;
  width: 100%;
  background-color: #272B2A;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 0 20px 0;
  padding: 15px;
  color: #fff;
  font-size: 15px;
}

.custom-box-2 {
  width: 100%;
  background-color: #272B2A;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 0 20px 0;
  padding: 15px;
  color: #fff;
  font-size: 15px;
}

@media only screen and (min-width: 768px) {
  .custom-box-2 {
    height: 350px;
    padding: 35px 15px 15px 15px;
  }
}

.custom-box-3 {
  width: 100%;
  background-color: #eee;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 0 20px 0;
  color: #fff;
  font-size: 15px;
}

.custom-box-3-image {
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px;
}

@media only screen and (min-width: 768px) {
  .custom-box-3-image {
    height: 150px;
  }
}

.custom-box-3-text {
  padding: 15px;
}

.custom-box-3 .custom-box-heading {
  color: #333 !important;
}

.custom-box-4 {
  height: 200px;
  width: 100%;
  background-color: #272B2A;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 0 20px 0;
  padding: 15px;
  color: #fff;
  font-size: 15px;
  position: relative;
}

.custom-box-4 a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}

.custom-box-heading {
  font-size: 16px;
  margin: 0 0 15px 0;
  display: block;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media only screen and (min-width: 768px) {
  .custom-box-heading {
    font-size: 18px;
  }
}

.custom-box-1 p,
.custom-box-2 p {
  color: #fff !important;
  font-size: 15px !important;
}

/* Design egna sidor */

/* Andra köpte också */
.tws-horizontal-flip-list--flip-container,
.tws-horizontal-flip-list--front,
.tws-horizontal-flip-list--back {
  width: 174px;
}

//MARCEL

// Varukorg

// .tws-micro-cart--counter::before {
//     z-index: 9999;
// }

// Search
// .tws-mega-menu--buttons {
//     display: none;
// }

// .tws-mega-menu--search-bar {
//     height: 45px;
//     margin-bottom: 2rem;
// }

body.scrolled .fabrikk--header {
  @media (min-width: 768px) {
    position: fixed;
    z-index: 9999;
    top: 0;
  }
}

body.scrolled .slot--back-button {
  @media (min-width: 768px) {
    display: none;
  }
}

body.scrolled .tws-free-shipping-notice {
  @media (min-width: 768px) {
    display: none;
  }
}

.fabrikk--header {
  // position: absolute;

  @media (min-width: 768px) {
    position: absolute;
    top: 32px;
  }
}

body.scrolled {

  .custom-logo,
  .header-logo,
  .cart-block {
    @media (min-width: 768px) {
      display: none;
    }
  }
}

@media (min-width:768px) {
  .fabrikk--body {
    padding-top: 273px;
  }
}

.checkout .fabrikk--body {
  @media (max-width:425px) {
    padding-top: 89px;
  }
}

.checkout .fabrikk--body {
    @media (min-width: 426px) and (max-width: 768px) {
      padding-top: 120px;
    }
  }

  .checkout .fabrikk--body {
    @media (min-width: 768px) {
      padding-top: 220px;
    }
  }







.tws-mega-menu--search-bar {
  margin-top: 1rem;
}

.tws-mega-menu--buttons {
  display: none;
}

.tws-mega-menu--search-bar .tws-article-search input.form-control::placeholder {
  color: #000 !important;
  opacity: 1;
}

.tws-mega-menu--search-bar .tws-article-search input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.tws-mega-menu .tws-article-search span::before {
  margin-top: -15px;
  font-size: 23px;
}


@media (max-width: 766px) {
  .tws-micro-cart--counter::before {
    content: url("data:image/svg+xml;charset=utf8,%3Csvg width='22' height='26' viewBox='21 117 18 21' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.918 124H21l1 14h16l1-14h-3.866c.02-.712-.08-1.52-.267-2.25-.584-2.285-2.185-3.75-4.867-3.75-2.684 0-4.272 1.466-4.845 3.757-.186.745-.252 1.51-.237 2.243zm2 0h6.187c.018-.596-.036-1.204-.176-1.755C32.556 120.79 31.694 120 30 120c-1.69 0-2.54.784-2.905 2.243-.14.565-.19 1.172-.176 1.757zm7.075 0H34v-.004l-.007.004z' fill='%23ffffff' fill-rule='evenodd'/%3E%3C/svg%3E") !important;
  }

  .tws-micro-cart--counter {
    color: #000;
  }

  .slot--horizontal-menu .tws-top-menu>.container-fluid>.navbar-collapse {
    max-width: 250px;
  }
}



@media (min-width: 767px) {
  .slot--custom-cart {
    z-index: 9999;
  }

  .slot--custom-cart {
    .tws-micro-cart--counter::before {
      content: '';
      background-image: url("https://shop.textalk.se/shop/2516/files/green.svg");
      // background-image: url('data:image/svg+xml, %3Csvg%20version%3D%221.0%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20width%3D%2264px%22%20height%3D%2264px%22%20viewBox%3D%220%200%2064%2064%22%20enable-background%3D%22new%200%200%2064%2064%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpolygon%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%20points%3D%221%2C25%2012%2C59%2052%2C59%2063%2C25%20%22%2F%3E%0A%3Cline%20fill%3D%22none%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%20x1%3D%2214%22%20y1%3D%2225%22%20x2%3D%2222%22%20y2%3D%225%22%2F%3E%0A%3Cline%20fill%3D%22%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%20x1%3D%2250%22%20y1%3D%2225%22%20x2%3D%2242%22%20y2%3D%225%22%2F%3E%0A%3C%2Fsvg%3E');
      display: block !important;
      // background-image: url('https://shop.textalk.se/shop/2516/files/ecommerce_basket.svg');
      background-size: 40px 40px;
      height: 40px;
      width: 40px;
      z-index: -1;
      position: absolute;
      left: -14px;
      top: -5px;
    }

    .tws-micro-cart--counter {
      padding-top: 14px;
      padding-right: 8px;
    }
  }

  .tws-micro-cart--label {
    margin-left: 15px;
  }
}


.cart-block {
  background: #F0EDE7;
}

@media (max-width:767px) {
  .big {
    display: none;
  }

  .small {
    display: inline-block;

    & span {
      display: block;
    }
  }

  .slot--logo {
    background-color: #191C23;
    // background-color: yellow;
  }

  .custom-logo {
    background-color: #191C23;
    // background-color: yellow;
  }
}

@media (min-width:768px) {
  .small {
    display: none;
  }

  .tws-mega-menu .tws-article-search span::before {
    margin-top: -21px;
    font-size: 30px;
  }

  .mega-menu-search-ny input[type=text].form-control {
    padding: 0px 40px 0px 65px;
  }
}

@media (max-width:425px) {
  .tws-micro-cart--counter {
    top: 20px;
  }

  .tws-top-menu .navbar-header {
    top: -65px;
  }

  .custom-logo {
    .small {
      max-width: 60%;
    }
  }
}

@media (min-width:425px) {
  .custom-logo {
    .small {
      max-width: 70%;
    }
  }
}

@media (min-width: 840px) {
  .slot--custom-vertical-menu {
    width: 175px;
  }
}

@media (max-width: 767px) {
  .fabrikk--body {
    padding-top: 155px;
  }
}